:root {
  .suggestion-hotel {
    .divider-suggestion-hotels {
      width: 100%;
      border: 1px dashed #d7d7d7;
      margin: 0 auto;
    }

    .hotel {
      width: 230px;
    }

    .hotel-image {
      height: 135px !important;
      margin-bottom: 16px;
    }

    .box-reserve-hotel {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 280px;
    }

    .box-search {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 54px;

      .search {
        position: relative;
        width: 270px;
        top: -25px;
      }

      .reset-hotels {
        cursor: pointer;
        margin-left: 16px;
      }
    }

    .button-status {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      width: 66px;
      height: 19px;
      border-radius: 16px;
      gap: 4px;

      flex: none;
      order: 1;
      flex-grow: 0;

      &.like {
        background: #d93f4c;
      }

      &.cheaper {
        background: #0a936d;
      }

      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        /* identical to box height */

        color: #ffffff;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0;
      }
    }

    .text-price {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #303030;
      margin-bottom: 4px;
    }

    .price {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      /* Neutral/black */

      color: #303030;
    }

    .button-reserve {
      width: 194px;
      margin: 0 auto;
    }

    .more-info {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-decoration-line: underline;
      color: #43418e;
      display: block;
      margin-top: 12px;
    }
  }
}
